@import '../../styles/index';

.base {
  margin-inline: auto;
  padding-block: 2rem;
  position: relative;

  @include media-from(sm) {
    padding: 5.25rem 4rem;
  }

  @include media-from(md) {
    padding: 5.25rem 10.5rem;
  }
}

.headline,
.description {
  margin: 0 0 1rem 0;
  position: relative;
  text-align: center;

  @include media-from(sm) {
    margin-bottom: 2rem;
  }

  @include media-from(md) {
    margin-bottom: 2.625rem;
  }
}

.headline {
  font-size: $font-size-36;
  line-height: 0.95;

  @include media-from(sm) {
    font-size: $font-size-72;
  }

  @include media-from(md) {
    font-size: $font-size-100;
  }
}

.description {
  font-size: $font-size-18;
  line-height: 1.6;

  @include media-from(sm) {
    font-size: $font-size-24;
  }

  @include media-from(md) {
    font-size: $font-size-30;
  }
}

.background {
  height: 100%;
  left: 0;
  overflow: hidden;
  perspective: 60rem;
  position: absolute;
  top: 0;
  width: 100%;
}

.icon-wrapper {
  position: absolute;
  transform-style: preserve-3d;

  &.small {
    max-width: 2.1rem;

    @include media-from(sm) {
      max-width: 2.65rem;
    }
  }

  &.large {
    max-width: 2.1rem;

    @include media-from(sm) {
      max-width: 4.375rem;
    }
  }

  .icon {
    width: 100%;
    height: 100%;
  }
}
