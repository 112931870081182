@import '../../styles/index';

.wrapper {
  display: block;
  width: 100%;
}

.base {
  display: flex;
  flex-wrap: wrap;

  margin-top: -1rem;

  & > label {
    margin: 1rem 1rem 0 0;

    @include media-from(sm) {
      margin: 1rem 1.5rem 0 0;
    }
  }
}

.label {
  font-weight: $font-weight-medium;
  font-size: $font-size-20;
}

.error-message-wrapper {
  position: relative;
  display: block;
  width: 100%;
}
