@import '../../styles/index';

.base {
  background-image: $gradient-enercity;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px transparent;
}

.text {
  font-size: $font-size-48;
  text-align: center;
  line-height: 1.3;

  strong {
    font-weight: $font-weight-medium;
  }

  @include media-from(sm) {
    font-size: $font-size-64;
  }
}
