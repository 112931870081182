@import '../../styles/index';

.native-select {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;

  &--mobile-only {
    @include media-from(xs) {
      display: none;
    }
  }

  &.select-only {
    width: 100%;
  }
}
