@import '../../styles/index';

.button-wrapper {
  display: flex;
}

.background {
  color: $surrogate-9;
  display: none;

  @include media-from(sm) {
    display: block;
  }

  :nth-child(1) {
    left: 20%;
    top: 21%;
  }

  :nth-child(2) {
    left: 13%;
    top: 40%;
  }

  :nth-child(3) {
    left: 21%;
    top: 69%;
  }

  :nth-child(4) {
    left: 31%;
    top: 76%;
  }

  :nth-child(5) {
    left: 62%;
    top: 77%;
  }

  :nth-child(6) {
    left: 75%;
    top: 67%;
  }

  :nth-child(7) {
    left: 81%;
    top: 47%;
  }

  :nth-child(8) {
    left: 77%;
    top: 20%;
  }
}

.description {
  margin-inline: auto;

  @include media-from(sm) {
    max-width: 45ch;
  }
}
